.header {
    height: min-content;
}

.navbar__logo {
    height: 6vh; /* 76px;*/
}

.navbar__container, .navbar__container > *, .navbar__container > * > * {
    width: 100%;
}

.navbar__container {
    flex-grow: 1;
    max-width: 100vw;
}

.navbar__drawer {
    overflow: auto;
    padding: 2%;
}

.navbar__action-button {
    color: #FFF;
    text-decoration: underline !important;
    /* border: 1px solid rgba(255, 255, 255, .3); */
}

.navbar__link-container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

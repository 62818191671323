.modalita__list-item {
  flex-direction: column;
}

.modalita__list-item > * {
  padding: 2% 5%;
}

.modalita__list-item:after {
  content: "";
  display: block;
  height: 1px;
  width: 75%;
  margin: auto;
  background-color: #E6E6E6;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem;
  gap: 3%;
}

.gallery__container {
  overflow: scroll;
  width: 100%;
  height: 100%;
}

.gallery__item {
  flex-basis: 47%;
}

.gallery__image {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, .2);
  transition: all .3s ease-in-out;

}

.gallery__image:hover {
  transform: scale(1.02);
}
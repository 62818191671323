.form__container {
  border-radius: 1%;
  padding: 2%;
  max-height: 90%;
  overflow-y: scroll;
  max-width: 90vw;
}

.form__item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0 !important;
}

.form__input {
  width: 80%;
  margin: auto;
}


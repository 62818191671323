@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Montserrat:wght@300;500;700&display=swap');

.navbar__container, .header {
  /*position: relative;*/
  z-index: 1;
  position: sticky;
  top: 0;
}

.achievements__container {
  width: 80vw;
  margin: auto;
}

.achievements__container > h1 {
  font-size: 5rem;
}

.achievements__content-element {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 5%;
  border-radius: 5px;
  padding: 5% 13%;
  box-shadow: 0 1rem 3rem rgba(211, 209, 209, 0.17) !important;
  background: rgba(211, 209, 209, .17);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.067);
  height: 100%;
  column-gap: 3%;
  transition: transform 0.5s ease-in-out;

}

.achievements__badge {
  width: 8vh;
  order: 1;
  transition: transform 0.5s ease-in-out;
}

.achievements__content-element:hover {
  transform: scale(1.01);
  backdrop-filter: blur(30px) drop-shadow(5px 5px 10px rgb(0 255 196));
  border-radius: 5px;
}

.achievements__title-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 80%;
  margin: auto;
  column-gap: 2%;
}

.achievements__page-title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
  z-index: 2;
}


.achievements__title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  text-decoration: underline;
  order: 2;
  color: rgb(0 255 196 / 80%);
}

.achievements__description {
  font-family: 'Anonymous Pro', monospace;
  width: 55%;
  color: rgb(0 255 196 / 40%);
  font-size: 1.3rem;
}

div.MuiTabs-flexContainer {
  justify-content: space-around;
}

div.MuiContainer-root {
  padding: 0;
}

.top-ranking__container, .arene__container {
  /*width: 80vw;*/
}

.top-ranking__box, .arene__box {
  margin: 4%;
  padding: 3%;
}

@media (max-width: 768px) {
  .top-ranking__container, .arene__container {
    width: 93vw;
  }

  .arene__box {
    margin: 2%;
    padding: 2%;
  }

  .top-ranking__box {
    margin: 2%;
    padding: 1%;
  }
}
.table__custom--head > tr > .table__custom--cell,
.table__custom--head__opaque > tr > .table__custom--cell {
  text-transform: uppercase;
  font-weight: bold;
}

.table__custom--body > .table__custom--row {
  text-align: start;
  color: #FFF !important;
}

.table__custom--row > .table__custom--cell__white {
  color: #FFF !important;
}

.table__custom--row > .table__custom--cell__primary {
  color: #1EDCFF !important;
}

.table__custom--body:last-child td, .table__custom--body:last-child th {
  border: 0
}

.table__custom--head__opaque {
  background-color: #B8B8B847;
}

.table__container--scrollable-in-view {
  width: 100%;
  height: 100%;
  max-height: 60vh;
}
.footer {
  margin-top: 1rem;
  padding: 1rem;
  padding-bottom: 0 !important;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.footer__container {
  /*position: fixed;*/
  bottom: 0;
  left: 0;
  width: 100%;
  align-self: end;
}

.body {
  align-self: center;
}

/* IN CASE OF ....*/
/*.footer__container {*/
/*  position: fixed;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  align-self: end;*/
/*}*/
/*.body {*/
/*  margin-bottom: 4vh;*/
/*}*/
/*@media (max-width: 768px) {*/
/*  .body {*/
/*    !*margin-bottom: 10vh;*!*/
/*  }*/
/*}*/
/* ....IN CASE OF*/


.footer__separator {
  width: 70%;
  margin: 1vh auto;
}

.footer__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20%;
  margin-bottom: 1rem;
}

.footer__icon {
  border-radius: 20%;
  width: 3vh;
}

.footer__icon:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
  border: 1px solid #FFF;
  cursor: pointer;
}
body {
    background-image: url(../../assets/Base-ebasta_web.jpg);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body, .App, #root {
    min-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overscroll-behavior-y: none;
}


.App {
    display: grid;
}

/* total width */
.container--scrollable::-webkit-scrollbar, .navbar--custom::-webkit-scrollbar {
    background-color: transparent !important;
    width: .38vw;
    height: .38vw;
}

@media (max-width: 768px) {
    .container--scrollable::-webkit-scrollbar, .navbar--custom::-webkit-scrollbar {
        width: 1vw;
        height: 1vw;
    }
}

/* background of the scrollbar except button or resizer */
.container--scrollable::-webkit-scrollbar-track, .navbar--custom::-webkit-scrollbar-track {
    background-color: transparent !important;
}

/* scrollbar itself */
.container--scrollable::-webkit-scrollbar-thumb, .navbar--custom::-webkit-scrollbar-thumb {
    background-color: #BABAC0;
    border-radius: 16px;
    /*border: 4px solid #FFF;*/
}

/* set button(top and bottom of the scrollbar) */
.container--scrollable::-webkit-scrollbar-button, .container--scrollable::-webkit-scrollbar-corner, .navbar--custom::-webkit-scrollbar-button, .navbar--custom::-webkit-scrollbar-corner {
    display: none;
}
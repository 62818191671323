:root {
  --color-primary: #1EDCFF;
  --color-primary--rbg: 30, 220, 255;
}

.glassmorphism {
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.067);
}

.datepicker--error > div {
  border: 1px solid red !important;
}

.datepicker--error > label {
  color: red !important;
}

.checkbox--error {
  color: red !important;
}

.checkbox--error ~ label {
  text-decoration: underline;
  text-decoration-color: red;
}

.alert__container {
  position: fixed;
  bottom: 10%;
  width: 30%;
  right: 2%;
  z-index: 9999;
}

.container--scrollable {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .alert__container {
    width: 100vw;
  }

  .alert__container > div {
    width: 90%;
    margin: auto;
  }
}


.link--no-color {
  color: inherit !important;
}

.circular-progress--centered {
  margin: auto !important;
}

.shiny {
  color: white;
  position: relative;
  border: none;
  padding: 10px 15px;
  background: #3984FF;
  border-radius: 10px;
  font-weight: bold;
  overflow: hidden;
}

.shiny::after {
  content: "";
  position: absolute;
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  width: 100px;
  height: 100px;
  background: radial-gradient(#0096889E, #3984FF00 85%);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s;
  transform: scale(1.2);
}

.shiny:hover::after {
  opacity: 0.4;
}

@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100;300;400&family=Poppins:wght@100;300;400;500;600;700&display=swap');

p {
  margin: 2px;
}

.radar--container {
  display: flex;
  width: 100%;
  height: 100%;
}

.statistic__container > div {
  border: 1px solid rgba(255, 255, 255, .5);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1% 0;
  height: 100%;
  margin: 0 auto;
  align-self: stretch;
}

.statistic__title {
  opacity: .5;
  display: block;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}

.statistic__value {
  display: block;
  text-align: center;
  width: 100%;
  font-family: 'Geologica', sans-serif;
  font-weight: 300;
}

.achievement__list {
  display: flex;
  align-items: center;
  padding: 0 4%;
  flex-direction: row;
  width: 80%;
  overflow-x: scroll;
  margin: auto;
  border: 1px solid rgba(255, 255, 255, .5);
}

.achievement__item {
  margin: 2%;
}
.page__subtitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: center;
  margin: 2% 0;
  text-transform: uppercase;
  color: var(--color-primary)
}